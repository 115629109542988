import Testo from "../../form/Testo";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/Check';

export default function Comp(props) {
  useEffect(()=>{
    ////redirect a home
    setTimeout(()=>{
      window.location.href="/"
    }, 10000)
  },[])

  return (
    <>
    <Grid container spacing={0}>
        <Grid item xs={4} ></Grid>
        <Grid item xs={8} >
      <h2 className="form_titolo center" style={{marginTop: 90}}>
       La tua richiesta è stata ricevuta, grazie.
        <br />A presto!
      </h2>
      <div style={{display: "flex", justifyContent: "center"}}>
    <CheckIcon className="iconaThank" />
      {/* <img src="/img/smile.png" style={{width: 250, marginTop: 20}} */}
      {/* /> */}
      </div>
      </Grid>
      </Grid>
    </>
  );
}
