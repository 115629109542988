import { Routes, Route, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import CssBaseline from "@mui/material/CssBaseline";

import Login from "./comp/admin/Login";
import Home from "./comp/tablet/Home";
import Layout from "./comp/tablet/Layout";
import Contatto from "./comp/tablet/Contatto";
import ListaSedi from "./comp/tablet/ListaSedi";
import ListaServiziSede from "./comp/tablet/ListaServiziSede";
import QrCode from "./comp/tablet/QrCode";
import Messaggioinviato from "./comp/tablet/MessaggioInviato";

import LayoutAdmin from "./comp/admin/LayoutAdmin";
// import HomeAdmin from "./comp/admin/HomeAdmin";
import ContattiLista from "./comp/admin/ContattiLista";
import ContattiModifica from "./comp/admin/ContattiModifica";
import RecuperaPassword from "./comp/admin/RecuperaPassword";
import ReimpostaPassword from "./comp/admin/ReimpostaPassword";
import { useEffect } from "react";


import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

function App() {
  useEffect(() => {
    //redirect se non c'è il cookie della sede
    
  });
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/recupera-password" element={<RecuperaPassword />} />
        <Route
          path="/admin/reimposta-password"
          element={<ReimpostaPassword />}
        />

        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/contatto"
            element={
              <GoogleReCaptchaProvider 
              reCaptchaKey="6LcNI1smAAAAALXWpmcMcdQZiWH_tM-0kaW5WTzN"
              container={{
                element: "contenitoreCaptcha",
                parameters: {
                  badge: 'bottomleft', 
                }
              }}
          
          >
                <Contatto />
              </GoogleReCaptchaProvider>
            }
          />
          <Route path="/lista-servizi" element={<ListaServiziSede />} />
          <Route path="/lista-sedi" element={<ListaSedi />} />
          <Route path="/qr-code/:url/:nome" element={<QrCode />} />
          <Route path="/thank-you" element={<Messaggioinviato />} />
        </Route>

        <Route path="/admin" element={<LayoutAdmin />}>
          <Route path="/admin" element={<ContattiLista />} />
          <Route
            path="/admin/contatto-modifica/:id"
            element={<ContattiModifica />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
