// import Testo from "../../form/Testo";
import { useState } from "react";
import { monFetch } from "../../inc/inc";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";

// const BloccoHome = function (props) {
//   const salvaVoto = async function (valore) {
//     const res = await monFetch("/ratingAdd", { grade: props.voto });
//     if(res.success){
//       window.location.href= props.url+"/"+res.rating_id
//     }
//   };

//   return (
//     <div
//       className="bloccoHome center"
//       style={{cursor: 'pointer'}}
//       onClick={() => {
//         salvaVoto(props.voto);
//       }}
//     >
//       <img src={props.immagine} />
//       <p className="center">{props.testo}</p>

//       <button className={"button " + props.classeBottone}>
//         {props.testoBottone}
//       </button>
//     </div>
//   );
// };

export default function Comp(props) {
  const [sedi, setSedi] = useState([]);

  const query = async () => {
    const res = await monFetch("/locationList", {});
    setSedi(res.res);
  };

  useEffect(() => {
    query();
    Cookies.remove("sede")
    setTimeout(() => {
      window.location.href = "/";
    }, 1000 * 180);
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={4} ></Grid>
        <Grid item xs={8} >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              flexWrap: "wrap",
            }}
          >
            {sedi.map((el) => {
              return (
                <div
                  className="bloccoSedi"
                  key={el.address}
                  onClick={(e) => {
                    Cookies.set("sede", el.address, { expires: 99 });
                    Cookies.set("sedeId", el.id, { expires: 99 });
                    window.location.href = "/";
                  }}
                >
                  <div className="cittaSede">{el.address.split(" - ")[0]}</div>
                  <div className="indirizzoSede"
                  style={{width: "100%"}}
                  >{el.address.split(" - ")[1]}</div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
