// import Testo from "../../form/Testo";
import { useState } from "react";
import { monFetch } from "../../inc/inc";
import Grid from "@mui/material/Grid";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect } from "react";
import Cookies from "js-cookie";

// const BloccoHome = function (props) {
//   const salvaVoto = async function (valore) {
//     const res = await monFetch("/ratingAdd", { grade: props.voto });
//     if(res.success){
//       window.location.href= props.url+"/"+res.rating_id
//     }
//   };

//   return (
//     <div
//       className="bloccoHome center"
//       style={{cursor: 'pointer'}}
//       onClick={() => {
//         salvaVoto(props.voto);
//       }}
//     >
//       <img src={props.immagine} />
//       <p className="center">{props.testo}</p>

//       <button className={"button " + props.classeBottone}>
//         {props.testoBottone}
//       </button>
//     </div>
//   );
// };

export default function Comp(props) {
  const [sedi, setSedi] = useState([]);

  const query = async () => {
    const res = await monFetch("/locationService", {
      location_id: Cookies.get("sedeId")
    });
    setSedi(res.res);
  };

  useEffect(() => {
    query();

    setTimeout(() => {
      window.location.href = "/";
    }, 1000 * 180);
  }, []);

  return (
    <>
      <div className="boxIndietro">
        <ArrowBackIosIcon onClick={()=>window.history.back()}/>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              flexWrap: "wrap",
            }}
          >
            {sedi.map((el) => {
              return (
                <div
                key={el.id}
                  className="bloccoSedi bloccoSediServizi"
                  onClick={(e) => {
                    window.location.href =
                      "/qr-code/" + btoa(el.link) + "/" + el.name;
                  }}
                >
                  <img src={"/img/logos/" + el.logo}></img>
                  
                  <div style={{flexGrow: 1}}>
                  {el.name}
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
