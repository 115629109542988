import Testo from "../../form/Testo";
import TextArea from "../../form/TextArea";
import { useParams } from "react-router-dom";
import SwitchPrivacy from "../../form/SwitchPrivacy";
import { useState, useEffect } from "react";
import { monFetch } from "../../inc/inc";
import Switch from "@mui/material/Switch";
import Cookies from "js-cookie";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import Grid from "@mui/material/Grid";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function Comp(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const params = useParams();
  const idVoto = params.id;

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [azienda, setAzienda] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [messaggio, setMessaggio] = useState("");

  const [categorieNlGlob, setCategorieNlGlob] = useState([]);
  const [categorieNlLoc, setCategorieNlLoc] = useState([]);

  const [tags2Active, setTags2Active] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  const [privacy, setPrivacy] = useState(false);

  const [comeConosciuti, setComeConosciuti] = useState("");

  const [msgErrore, setMsgErrore] = useState({
    msg: null,
    campi: [],
    campiRequired: [],
  });

  let classeBottone = "button_ok";

  const inviaForm = async function (e) {
    e.preventDefault();

    ////Recaptcha
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha();

    const res = await monFetch("/contactAdd", {
      first_name: nome,
      last_name: cognome,
      company: azienda,
      privacy: privacy,
      mobile: telefono,
      email: email,
      rating_id: idVoto,
      message: messaggio,
      // industry: industria,
      // fine_arts: belleArti,
      // ironmongery: ferramenta,
      // paint: idropittura,
      how_know: comeConosciuti,
      newsletter_category_glob: categorieNlGlob,
      newsletter_category_loc: categorieNlLoc,
      token: token,
    });
    if (res.success) {
      window.location.href = "/thank-you";
    } else {
      //genero messaggio errore
      let msg = { ...msgErrore };
      if (res.inOr) {
        msg.msg = "Inserire almeno uno dei dati evidenziati";
        res.inOr.forEach((el) => {
          //popolo array di campi con errore
          msg.campi.push(el);
        });
      }

      setMsgErrore(msg);
    }
  };

  const queryCategorie = async function () {
    const res = await monFetch("/newsletterCategoryList", {
      location_id: Cookies.get("sedeId"),
    });
    if (res.success) {
      res.glob.forEach((el) => {
        el.active = false;
      });
      res.loc.forEach((el) => {
        el.active = false;
      });
      setCategorieNlGlob(res.glob);
      setCategorieNlLoc(res.loc);
    }
  };

  const controllaCampiRequired = () => {
    let msg = { ...msgErrore };
    msg.campiRequired = [];
    if (!nome) {
      msg.campiRequired.push("nome");
    }
    if (!cognome) {
      msg.campiRequired.push("cognome");
    }

    setMsgErrore(msg);
  };

  const settaSwitchNlLoc = (arr, index, key, value) => {
    let temp = [...arr];
    temp[index][key] = value;

    let active = false;
    arr.forEach((el) => {
      if (el.active) {
        active = true;
      }
    });

    setTags2Active(active);

    setCategorieNlLoc(temp);
  };

  const settaSwitchNlGlob = (arr, index, key, value) => {
    let temp = [...arr];
    temp[index][key] = value;

    let active = false;
    arr.forEach((el) => {
      if (el.active) {
        active = true;
      }
    });

    setButtonActive(active);

    setCategorieNlGlob(temp);
  };

  useEffect(() => {
    queryCategorie();
    let timeout2;
    //redirect a home dopo inattività
    function attivaTimer() {
      timeout2 = setTimeout(() => {
        window.location.href = "/";
      }, 60000);
    }

    attivaTimer();

    //cancella timer al movimento del mouse

    function clearTimer() {
      clearTimeout(timeout2);
      attivaTimer();
    }
    document.addEventListener("mousemove", clearTimer);
    document.addEventListener("keyup", clearTimer);
    document.addEventListener("ontouchstart", clearTimer);
  }, []);

  return (
    <>
      <div className="boxIndietro">
        <ArrowBackIosIcon onClick={() => (window.location.href = "/")} />
      </div>
      <Grid container spacing={0} style={{ marginTop: -20 }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <form
            onSubmit={(e) => {
              inviaForm(e);
            }}
          >
            <h2>{props.titolo}</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Testo
                  className={
                    msgErrore.campiRequired.includes("nome") ? "error" : ""
                  }
                  value={nome}
                  setValue={setNome}
                  placeholder="Nome*"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Testo
                  className={
                    msgErrore.campiRequired.includes("cognome") ? "error" : ""
                  }
                  required
                  value={cognome}
                  setValue={setCognome}
                  placeholder="Cognome*"
                />
              </Grid>
              <Grid item xs={12}>
                <Testo
                  className={
                    msgErrore.campiRequired.includes("azienda") ? "error" : ""
                  }
                  value={azienda}
                  setValue={setAzienda}
                  placeholder="Azienda"
                />
              </Grid>
              <Grid item xs={6}>
                <Testo
                  className={
                    msgErrore.campi.includes("mobile") ? "warning" : ""
                  }
                  value={telefono}
                  setValue={setTelefono}
                  placeholder="Telefono"
                />
              </Grid>
              <Grid item xs={6}>
                <Testo
                  className={msgErrore.campi.includes("email") ? "warning" : ""}
                  value={email}
                  setValue={setEmail}
                  placeholder="Mail"
                  type="email"
                />
              </Grid>

              <Grid item xs={12}>
                <TextArea
                  className={
                    msgErrore.campi.includes("comeConosciuti") ? "warning" : ""
                  }
                  value={comeConosciuti}
                  setValue={setComeConosciuti}
                  placeholder="Come ci hai conosciuti?"
                />
              </Grid>

              <Grid item xs={12}>
                <div className="form_description center testoGrande">
                  Seleziona a quali brand sei interessato <small>(almeno uno)</small>
                </div>
                <div className="selettori">
                  {categorieNlLoc.map((el, index) => {
                    return (
                      <div key={el.id}>
                        <Switch
                          checked={el.active || false}
                          onChange={(event) =>
                            settaSwitchNlLoc(
                              categorieNlLoc,
                              index,
                              "active",
                              event.target.checked
                            )
                          }
                          className="switch_privacy"
                        />
                        <span className="labelswitch  testoGrande">
                          {el.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="form_description center testoGrande"
                  style={{ marginTop: 20 }}
                >
                  Seleziona a quali categorie sei interessato <small>(almeno una)</small>
                </div>
                <div className="selettori">
                  {categorieNlGlob.map((el, index) => {
                    return (
                      <div key={el.id}>
                        <Switch
                          checked={el.active || false}
                          onChange={(event) =>
                            settaSwitchNlGlob(
                              categorieNlGlob,
                              index,
                              "active",
                              event.target.checked
                            )
                          }
                          disabled={!tags2Active}
                          style={{ opacity: !tags2Active ? 0.5 : 1 }}
                          className="switch_privacy"
                        />
                        <span className="labelswitch  testoGrande">
                          {el.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Grid>
              {/* messaggio errore */}
              {msgErrore.msg && (
                <Grid item xs={12}>
                  <div
                    className="msgErrore"
                    dangerouslySetInnerHTML={{ __html: msgErrore.msg }}
                  ></div>
                </Grid>
              )}

              <Grid item xs={12}>
                <p className="form_description">
                  I campi contrassegnati con (*) sono obbligatori. Tutte le
                  comunicazioni saranno trattate nel pieno rispetto della
                  Privacy e della normativa vigente ai sensi del Reg.to UE
                  2016/679 (GDPR).
                </p>
              </Grid>
            </Grid>

            <div className="flex space_between align_center">
              <div className="flex">
                <SwitchPrivacy
                  className="switch_privacy ruotato"
                  privacy={privacy}
                  setPrivacy={setPrivacy}
                />

                <span className="form_description">
                  Dichiaro di aver letto e accettato l'informativa sulla Privacy
                  ex artt. 13-14 Reg.to UE 2016/679 e autorizzo il trattamento
                  dei dati personali ai sensi della normativa vigente.
                </span>
              </div>
              <div>
                <button
                  className={"button " + classeBottone}
                  disabled={!privacy || !buttonActive}
                  onClick={() => {
                    controllaCampiRequired();
                  }}
                >
                  Invia
                </button>
              </div>
            </div>
          </form>
          <div id="contenitoreCaptcha"></div>
        </Grid>
      </Grid>
    </>
  );
}
