import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { QRCode } from "react-qrcode-logo";
import { useEffect } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function Comp(props) {
  let params = useParams();

  let url = params.url;
  let nome = params.nome;

  useEffect(()=>{
    //redirect home dopo inattività
    setTimeout(()=>{
      window.location.href="/";
    }, 1000 * 180)
  }, [])

  return (
    <>
    <div className="boxIndietro">
        <ArrowBackIosIcon onClick={()=>window.location.href="/"}/>
      </div>
    <Grid container spacing={0}>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
      <div
        className="flex"
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
          flexWrap: "wrap",
        }}
      >
        <QRCode
          value={atob(url)}
          size={500}
          logoImage="/img/logo_qr.png"
          removeQrCodeBehindLogo={true}
          logoPadding={10}
          logoWidth={150}
          logoHeight={95}
          bgColor={"transparent"}
        />
        <h1 style={{ width: "100%", textAlign: "center" }}>{nome}</h1>
      </div>
      </Grid>
      </Grid>
    </>
  );
}
