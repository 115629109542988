import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { convertiData, monFetch } from "../../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";

import Checkbox from "@mui/material/Checkbox";

import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const Comp = (props) => {
  let location = useLocation();
  let params = useParams();

  let id = params.id;

  const [azienda, setAzienda] = useState("");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [data, setData] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [comeConosciuti, setComeConosciuti] = useState("");
  const [categorieLoc, setCategorieLoc] = useState([]);
  const [categorieGlob, setCategorieGlob] = useState([]);

  async function query() {
    const res = await monFetch("/contactGet", { id });
    let res2 = res.res;
    setAzienda(res2.company || "");
    setNome(res2.first_name || "");
    setCognome(res2.last_name || "");
    setEmail(res2.email || "");
    setTelefono(res2.mobile || "");
    setData(res2.timestamp.split(" ")[0] || "");
    setComeConosciuti(res2.how_know || "");

    setCategorieLoc(res.catLoc);
    setCategorieGlob(res.catGlob);
  }

  function settaCategoriaLoc(index, key, value) {
    let temp = [...categorieLoc];
    temp[index][key] = value;

    setCategorieLoc(temp);
  }

  function settaCategoriaGlob(index, key, value) {
    let temp = [...categorieGlob];
    temp[index][key] = value;

    setCategorieGlob(temp);
  }

  async function modificaContatto() {
    let catLoc = [];
    categorieLoc.forEach((el) => {
      if (el.active) {
        catLoc.push(el.id);
      }
    });

    let catGlob = [];
    categorieGlob.forEach((el) => {
      if (el.active) {
        catGlob.push(el.id);
      }
    });

    const res = await monFetch("/contactEdit", {
      id: id,
      company: azienda,
      first_name: nome,
      last_name: cognome,
      mobile: telefono,
      categoriesLoc: catLoc,
      categoriesGlob: catGlob,
    });

    window.location.href = "/admin";
  }

  useEffect(() => {
    query();
  }, []);

  return (
    <>
      <h2>Modifica dati contatto</h2>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Email"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={email}
            disabled
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Azienda"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={azienda}
            onChange={(e) => {
              setAzienda(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Cognome"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={cognome}
            onChange={(e) => {
              setCognome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Telefono"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={telefono}
            onChange={(e) => {
              setTelefono(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            label="Data"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            disabled
            variant="outlined"
            className="inputCustom"
            value={data}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label="Come ci hai conosciuti"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={comeConosciuti}
            disabled
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <h3>Marchi</h3>
          <div style={{ display: "flex" }}>
            {categorieLoc.map((el, index) => {
              return (
                <div className="labelCheck" key={index}>
                  <Checkbox
                    checked={el.active ? true : false}
                    onChange={(e) => {
                      settaCategoriaLoc(index, "active", e.target.checked);
                    }}
                  />
                  {el.name}
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item sm={12} xs={12}>
          <h3>Servizi</h3>
          <div style={{ display: "flex" }}>
            {categorieGlob.map((el, index) => {
              return (
                <div className="labelCheck" key={index}>
                  <Checkbox
                    checked={el.active ? true : false}
                    onChange={(e) => {
                      settaCategoriaGlob(index, "active", e.target.checked);
                    }}
                  />
                  {el.name}
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button
            variant="contained"
            onClick={(e) => {
              modificaContatto();
            }}
          >
            Salva
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Comp;
