import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { convertiData, monFetch } from "../../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";

const Comp = (props) => {
  let location = useLocation();

  const [contatti, setContatti] = useState([]);

  const [dataInizio, setDataInizio] = useState(null);
  const [dataFine, setDataFine] = useState(null);
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [voto, setVoto] = useState("");

  const [totale, setTotale] = useState(0);

  const [options, setOptions] = useState([
    { label: "Tutti", value: "" },
    { label: "Si", value: 1 },
    { label: "No", value: 0 },
  ]);

  const [optionsCategorieLoc, setOptionsCategorieLoc] = useState([]);
  const [optionsCategorieGlob, setOptionsCategorieGlob] = useState([]);

  const [categorieLoc, setCategorieLoc] = useState([]);
  const [categorieGlob, setCategorieGlob] = useState([]);

  const [numeroPagine, setNumeroPagine] = useState(1);
  const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(50);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [googleSync, setGoogleSync] = useState(false);
  const [mailSync, setMailSync] = useState(false);

  async function query(data = {}) {
    const res = await monFetch("/contactList", data);
    setContatti(res.contacts);
    setNumeroPagine(Math.ceil(res.tot / risPerPagina));
    setTotale(res.tot);
  }

  const queryCategorie = async function () {
    const res = await monFetch("/newsletterCategoryList", {});
    if (res.success) {
      let temp = [];
      res.glob.forEach((el) => {
        temp.push({ label: el.name, value: el.id });
      });

      setOptionsCategorieGlob(temp);

      let temp2 = [];
      res.loc.forEach((el) => {
        temp2.push({ label: el.name, value: el.id });
      });

      setOptionsCategorieLoc(temp2);
    }
  };

  useEffect(() => {
    let catLoc = [];
    categorieLoc.forEach((el) => {
      catLoc.push(el.value);
    });

    let catGlob = [];
    categorieGlob.forEach((el) => {
      catGlob.push(el.value);
    });

    query({
      dateFrom: dataInizio,
      dateTo: dataFine,
      mobileRequired: telefono,
      emailRequired: email,
      grade: voto,
      pagina: pagina,
      rec_for_page: risPerPagina,
      categoriesLoc: catLoc,
      categoriesGlob: catGlob,
    });
  }, [dataInizio, dataFine, telefono, email, voto, pagina, categorieLoc, categorieGlob]);

  useEffect(() => {
    queryCategorie();
  }, []);

  async function syncMailchimp(data) {
    setMailSync(true);
    const res = await monFetch("/syncContacts");
    if (res.success) {
      window.location.href = "/admin/contatti";
    }
  }

  async function syncGoogle() {
    let url =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      "?client_id=" +
      process.env.REACT_APP_GOOGLE_CLIENT_ID +
      "&redirect_uri=https://soddisfazione.bianchessiauto.it/admin" +
      "&response_type=token" +
      "&scope=" +
      process.env.REACT_APP_GOOGLE_SCOPE;

    //al termine reindirizza al redirect_uri sopra
    // console.log(url)
    // return

    window.location.href = url;

    // const res = await fetch(url, {
    //   method: "GET",
    //   // headers: {
    //   //   "Content-Type": "application/json",
    //   //   monToken: Cookies.get("token"),
    //   //   monId: Cookies.get("id"),
    //   //   monComId: Cookies.get("companyId"),
    //   // },
    //   // body: JSON.stringify(data),
    // });
    // console.log(res)
  }

  useEffect(() => {
    async function fn() {
      /////se access token dopo redirect
      let token = new URLSearchParams(window.location.hash).get(
        "#access_token"
      );
      console.log(token);
      if (token) {
        setGoogleSync(true);
        const res = await monFetch("/syncGoogle", { token });
        if (res.success) {
          window.location.href = "/admin";
        }
      }
    }
    fn();
  }, []);

  async function eliminaContatto(id) {
    const res = await monFetch("/contactDelete", { id: id });
    if (res.success) {
      query();
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <h1 style={{ margin: "10px 0", fontSize: "40px" }}>
            Elenco richieste
          </h1>
        </Grid>
        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            fullWidth
            onClick={(e) => syncMailchimp()}
            disabled={true || mailSync}
          >
            Sync Mailchimp
          </Button>
        </Grid>
        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            fullWidth
            disabled={googleSync}
            onClick={(e) => syncGoogle()}
          >
            Sync Google Contacts
          </Button>
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            label="Data Inizio"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            variant="outlined"
            className="inputCustom"
            value={dataInizio || ""}
            onChange={(e) => {
              setDataInizio(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            label="Data Fine"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            variant="outlined"
            className="inputCustom"
            value={dataFine || ""}
            onChange={(e) => {
              setDataFine(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={1} xs={12}>
          {/* //cell */}
          <Autocomplete
            value={options.find((op) => op.value === telefono)}
            onChange={(e, value) => {
              if (!value) {
                setTelefono("");
                return;
              }
              setTelefono(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Telefono"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //email */}
          <Autocomplete
            value={options.find((op) => op.value === email)}
            onChange={(e, value) => {
              if (!value) {
                setEmail("");
                return;
              }
              setEmail(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          {/* categorie */}
          <Autocomplete
            multiple
            value={categorieLoc}
            onChange={(e, value) => {
              console.log(value);
              setCategorieLoc(value);
            }}
            options={optionsCategorieLoc}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Marchi"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          {/* categorie */}
          <Autocomplete
            multiple
            value={categorieGlob}
            onChange={(e, value) => {
              console.log(value);
              setCategorieGlob(value);
            }}
            options={optionsCategorieGlob}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Servizi"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="risultati">Risultati: {totale}</div>
        </Grid>
      </Grid>

      <Table id="tabellaAttivita">
        <TableHead>
          <TableRow>
            <TableCell>Azienda</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Cognome</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telefono</TableCell>
            <TableCell>Marchi</TableCell>
            <TableCell>Servizi</TableCell>
            <TableCell>Sync</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contatti.map((t) => {
            return (
              <TableRow key={t.id}>
                <TableCell>{t.company}</TableCell>
                <TableCell>{t.first_name}</TableCell>
                <TableCell>{t.last_name}</TableCell>
                <TableCell>{convertiData(t.timestamp)}</TableCell>
                <TableCell>{t.email}</TableCell>
                <TableCell>{t.mobile}</TableCell>
                <TableCell>
                  {t.catLoc?.split(",").map((el, index) => {
                    let sep = " | ";
                    if (index + 1 == t.catLoc.split(",").length) {
                      sep = "";
                    }
                    return el + sep;
                  })}
                </TableCell>
                <TableCell>
                  {t.catGlob?.split(",").map((el, index) => {
                    let sep = " | ";
                    if (index + 1 == t.catGlob.split(",").length) {
                      sep = "";
                    }
                    return el + sep;
                  })}
                </TableCell>
                <TableCell>
                  <EmailIcon
                    style={{
                      color: t.mailchimp_sync ? "#3DCD70" : "#ccc",
                      marginRight: 10,
                    }}
                  />
                  <GoogleIcon
                    style={{ color: t.google_sync ? "#3DCD70" : "#ccc" }}
                  />
                </TableCell>

                <TableCell>
                  <Button
                    variant="contained"
                    href={"/admin/contatto-modifica/" + t.id}
                    style={{ margin: "3px 5px" }}
                  >
                    <RemoveRedEyeIcon />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      setPopupElimina(true);
                      setDaEliminare(t.id);
                    }}
                    color="error"
                    style={{ margin: "3px 5px" }}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <Pagination
        count={numeroPagine}
        page={pagina}
        onChange={(e, v) => setPagina(v)}
      />
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => {
          eliminaContatto(daEliminare);
          setPopupElimina(false);
        }}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
