// import Testo from "../../form/Testo";
// import { useState } from "react";
// import { monFetch } from "../../inc/inc";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

// const BloccoHome = function (props) {
//   const salvaVoto = async function (valore) {
//     const res = await monFetch("/ratingAdd", { grade: props.voto });
//     if(res.success){
//       window.location.href= props.url+"/"+res.rating_id
//     }
//   };

//   return (
//     <div
//       className="bloccoHome center"
//       style={{cursor: 'pointer'}}
//       onClick={() => {
//         salvaVoto(props.voto);
//       }}
//     >
//       <img src={props.immagine} />
//       <p className="center">{props.testo}</p>

//       <button className={"button " + props.classeBottone}>
//         {props.testoBottone}
//       </button>
//     </div>
//   );
// };

export default function Comp(props) {
  return (
    <>
      <div className="boxSedeLogin">
        {Cookies.get("sede") ? (
          <>
            <b>{Cookies.get("sede")}</b> <a href="/lista-sedi" className="tastoCambia">Cambia</a>
          </>
        ) : (
          ""
        )}
      </div>
      <Grid container spacing={0}>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <div className="flex">
            <div
              className="bloccoHome"
              onClick={() => {
                window.location.href = "/lista-servizi";
              }}
            >
              <QrCodeIcon />
              <p>Lasciaci un feedback</p>
            </div>
            <div
              className="bloccoHome"
              style={{marginRight: 0}}
              onClick={() => {
                window.location.href = "/contatto";
              }}
            >
              <MailOutlineIcon />
              <p>Comunicazioni commerciali</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
