import { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Container from "@mui/material/Container";
import { controllaLogin } from "../../inc/inc";

const Comp = (props) => {
  let location = useLocation();

  useEffect(() => {
    controllaLogin();
  }, []);

 

  return (
    <div id="rootTablet">
      {/* <div className="logo">
         <img
          src="/img/logo.png"
          onClick={() => {
            window.location.href = "/";
          }}
        ></img> 
      </div> */}
      <Container className="container">
        <div className="centratoVert">
          <div style={{ width: " 100%" }}>
            <Outlet />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Comp;
